<template>
  <v-layout column wrap>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF PAID DUE MEMBERS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-2">
        <v-flex xs12 md4>
          <v-select
            v-model="district"
            dense
            outlined
            label="District/Club"
            :items="district_items"
            item-value="id"
            item-text="district_name"
            @change="get_list_of_new_member_items"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="align-center mx-2 mt-2">
        <v-flex xs12 md4>
          <v-select
            v-model="year"
            dense
            outlined
            label="Year"
            :items="year_items"
            item-value="year"
            item-text="year"
            :rules="rules.combobox_rule"
            @change="get_list_of_new_member_items"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-simple-table dense>
        <template v-slot:top>
          <v-toolbar flat>
            <span class="headline">Total: {{totall}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="headline" v-if="can_print">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
          </v-toolbar>
        </template>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              Year of
            </th>
            <th class="text-uppercase">
              CMF #
            </th>
            <th class="text-uppercase">
              Name
            </th>
            <th class="text-center text-uppercase">
              Contact #
            </th>
            <th class="text-center text-uppercase">
              Status
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in member_items"
            :key="item.id"
          >
            <td>
              <v-chip color="info" dark>
                {{ item.year_of}}
              </v-chip>
            </td>
            <td>
              {{ item.members.cmf_no }}
            </td>
            <td>
              {{ item.members.last_name+', '+item.members.first_name+' '+item.members.middle_name }}
            </td>
            <td class="text-center">
              {{ item.members.contact_no }}
            </td>
            <td class="text-center">
              <div
                v-if="item.deposit_slip_id!=null">
                <v-chip color="success" dark>PAID</v-chip>
              </div>
              <div v-else>
                <v-chip color="error" dark>UNPAID</v-chip>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-layout>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import moment from "moment";
  import {mdiAccountSearchOutline, mdiPrinter} from '@mdi/js'

  export default {
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        key: 0,
        can_print: false,
        district_items: [],
        year_items: [],
        district: '',
        year: 'All',

        officers: [],
        member_items: [],
        unpaid_members: 0,
        totall: 0,

        district_namee: '',
      }
    },
    mounted() {
      this.retrieve_all_transaction_year()
        .then(response => {
          this.year_items = response.data
          this.year_items.splice(0, 0, {
            year: 'All'
          })
        })
      if (this.district_id === null) {
        this.list_of_district()
          .then(response => {
            this.district_items = response.data
            this.district_items.splice(0, 0, {
              id: 0,
              district_name: 'All'
            })
          })
      } else {
        this.district_items.push({
          'id': this.district_id,
          'district_name': this.district_name,
        })
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch_id', 'branch', 'branch_address', 'branch_contact_no', 'district_id', 'district_name']),
    },
    watch: {
      branch_id: function () {
        this.can_print = false
        this.month_of = ''
        this.list_of_new_member = []
      }
    },
    methods: {
      ...mapActions('transaction_months', ['retrieve_all_transaction_year']),
      ...mapActions('district', ['list_of_district']),
      ...mapActions('members_information', ['list_of_paid_due_members']),
      due_date_format(value, ded) {
        return ded === 1 ? (moment(value).subtract(1, 'year').format('YYYY')) : (moment(value).format('YYYY'))
      },
      get_list_of_new_member_items() {
        if (this.district_items.length > 0) {
          var index = this.district_items.map(function (x) {
            return x.id
          }).indexOf(this.district)
          if (index != -1) {
            this.data_table_loading = true
            const data = new FormData()
            data.append('district_id', this.district_items[index].id);
            data.append('year', this.year);
            this.district_namee = this.district_items[index].district_name;
            this.list_of_paid_due_members(data)
              .then((response) => {
                this.member_items = response.data
                this.can_print = this.member_items.length > 0
                this.totall= this.formatPrice(this.member_items.length*1000)
              })
              .catch(error => {
                console.log(error)
              })
          }
        }
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      print_data() {
        var imgData = this.company_logo
        var array = []
        array.push(
          [
            {text: 'Year Of', alignment: 'center', style: 'label'},
            {text: 'CMF #', alignment: 'center', style: 'label'},
            {text: 'Name', alignment: 'center', style: 'label'},
            {text: 'Contact #', alignment: 'center', style: 'label'},
          ]
        )

        if (this.member_items.length > 0) {
          this.member_items.forEach(function (item) {
            array.push(
              [
                {text: item.year_of, alignment: 'center'},
                {text: item.members.cmf_no, alignment: 'left'},
                {
                  text: item.members.last_name + ', ' + item.members.first_name + ' ' + item.members.middle_name,
                  alignment: 'left'
                },
                {
                  text: item.members.contact_no,
                  alignment: 'left'
                },
              ]
            )
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE COMMUNITY CARE FOUNDATION',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C REG. No. NA CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF DUE MEMBERS', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {
              text: 'District: ' + this.district_namee,
              style: 'main_info'
            },
            {
              text: 'Year of: ' + this.year,
              style: 'main_info'
            },
            {
              text: 'Total: ' + this.totall,
              style: 'main_info'
            },
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [120, 50, 230, 80],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
